<template>
  <div class="section__lk-content container-shadow fill-white pb-5" v-if="mspForsageOperator">
    <div class="section__lk-title">
      {{ this.$route.meta.name }}
    </div>
    <div>
      <label for="tab"><span>Выберите регион</span></label>
      <v-select return-object :searchable="false" :clearable="false" id="region" :loading="!regions.length" :disabled="regions.length === 1" :label="'region_name_ru'" name="region" :options="regions" v-model="region" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import mspForsageOperatorMixin from "@/mixins/mspForsageOperatorMixin";
import {mapActions, mapMutations} from "vuex";
import {eventBus} from "@/main";

const DEFAULT_REGION = {
  id: null,
  region_name_ru: 'Выберите регион'
}

export default {
  name: "MspForsageOperator",
  mixins: [mspForsageOperatorMixin],
  data() {
    return {
      region: DEFAULT_REGION
    }
  },
  computed: {
    regions() {
      if(!this.mspForsageOperator) {
        return []
      }
      const program = parseInt(this.currentProgram);
      if (program === 1) {
        return this.mspForsageOperator.forsage_regions ? this.mspForsageOperator.forsage_regions : []
      } else if(program === 2) {
        return this.mspForsageOperator.msp_regions ? this.mspForsageOperator.msp_regions : []
      }
      return []
    }
  },
  methods: {
    ...mapActions(['getMspForsageOrders', 'getMspForsageGroups']),
    ...mapMutations(['setMspForsageOrders', 'setMspForsageGroups'])
  },
  mounted() {
    if(this.mspForsageOperator && this.mspForsageOperator.id && !this.currentProgram) {
      this.$router.push('/lk/msp-forsage/orders?program=' + this.mspForsageOperator.programs[0].id)
    }
    eventBus.$on('msp-groups:update', async () => {
      await this.getMspForsageOrders({program: this.currentProgram, region: this.region.id})
      await this.getMspForsageGroups({program: this.currentProgram, region: this.region.id})
    })
  },
  watch: {
    regions(val, oldVal) {
      if(val !== oldVal) {
        if(val.length === 1) {
          this.region = this.regions[0]
        } else {
          this.region = DEFAULT_REGION
        }
      }
    },
    currentProgram(val) {
      if(this.region.id) {
        this.getMspForsageOrders({program: val, region: this.region.id})
        this.getMspForsageGroups({program: val, region: this.region.id})
      } else {
        this.setMspForsageOrders([])
        this.setMspForsageGroups([])
      }
    },
    region(val) {
      if(val.id) {
        this.getMspForsageOrders({program: this.currentProgram, region: val.id})
        this.getMspForsageGroups({program: this.currentProgram, region: val.id})
      }
    },
    mspForsageOperator(val) {
      if(val.id && !this.currentProgram) {
        this.$router.push('/lk/msp-forsage/orders?program=' + val.programs[0].id)
      }
    }
  }
}
</script>

<style scoped>

</style>